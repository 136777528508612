import { useState, useEffect } from 'react';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../organisms/Layout';
import lightImg from "../data/light.jpg"
import darkImg from "../data/dark.jpg"
import yellowImg from "../data/yellow.jpg"

export const Theme = () => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue((event.target).value);
  };

  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  useEffect(() => {
    setValue(colorMode.mode );
  }, []); // 空の依存配列を渡して、コンポーネントのマウント時にのみ実行されるように

  return (

              <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Grid container spacing={4} >
    <Grid item xs={12} sm={4} md={4}>
    <Card sx={{
              border: `${
                value === "light" ? "2px solid blue" : "1px solid black"
              }`,
              m: "8px 0",
              borderRadius: "4px",
            }} onClick={() => {colorMode.colorMode.setLight(); setValue('light'); }}>
    <CardActionArea>
    <CardContent>
      <Stack spacing={1}>
      <img src={lightImg} />
    </Stack>

    <Typography variant="body2" color="text.secondary">
          <FormControlLabel
            value="light"
            control={<Radio />}
            label="Light"

          />
          </Typography>
    </CardContent>
    </CardActionArea>
    </Card></Grid>

    <Grid item xs={12} sm={4} md={4} >
    <Card sx={{
              border: `${
                value === "dark" ? "2px solid blue" : "1px solid black"
              }`,
              m: "8px 0",
              borderRadius: "4px",
            }} onClick={() => {colorMode.colorMode.setDark(); setValue('dark');}}>
    <CardActionArea>
    <CardContent>
      <Stack spacing={1}>
      <img src={darkImg} />
    </Stack>

    <Typography variant="body2" color="text.secondary">
    <FormControlLabel
            value="dark"
            control={<Radio />}
            label="Dark"

          />
          </Typography>
    </CardContent>
    </CardActionArea>
    </Card></Grid>

    <Grid item xs={12} sm={4} md={4} >
    <Card sx={{
              border: `${
                value === "yellow" ? "2px solid blue" : "1px solid black"
              }`,
              m: "8px 0",
              borderRadius: "4px",
            }} onClick={() => {colorMode.colorMode.setYellow(); setValue('yellow');}}>
    <CardActionArea>
    <CardContent>
      <Stack spacing={1}>
      <img src={yellowImg} />
    </Stack>

    <Typography variant="body2" color="text.secondary">
    <FormControlLabel
            value="yellow"
            control={<Radio />}
            label="Yellow"

          />
          </Typography>
    </CardContent>
    </CardActionArea>
    </Card>
    </Grid>
    </Grid>
    </Container>
    </RadioGroup>

  );
};
